body {
    margin: 0;
}

.site-title {
    font-size: 2rem;
}

.nav {
    background-color: rgb(218, 37, 28);
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 2rem;
    padding: 0 1rem;
    order: -1;
}

.nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem;
}

.nav a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0.25rem;
}

.nav li.active {
    background-color: rgb(118, 37, 28);
}

.nav li:hover {
    background-color: rgb(177, 37, 28);
}

@media (max-width: 664px) {
    .site-title {
        justify-content: center;
    }

    .nav {
        gap: 0rem;
        flex-flow: column;
        padding: 0;
    }

    .nav ul {
        gap: 0rem;
        justify-content: space-between;
    }
}
